<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column label="背景图" show-overflow-tooltip>
        <template v-slot="s">
          <img :src="s.row.bg | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem">
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template slot="header">
          <el-button @click="$router.push({
          name:'shop-system-rule-poster-v2-edit',
          })">添加海报</el-button>
        </template>
        <template v-slot="s">
          <el-button @click="$router.push({
          name:'shop-system-rule-poster-v2-edit',
          query:{
            id:s.row.id,
          }
          })" type="primary">编辑</el-button>
          <el-button @click="clear(s.row)" type="info">清缓存</el-button>
          <el-button @click="del(s.row)" type="info">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: "poster",
  data() {
    return {
      list: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    clear({id}){
      this.$u.api.shop.sys.posterV2Clear({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    del({id}){
      this.$u.api.shop.sys.posterV2Del({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.$u.api.shop.sys.posterV2List().then(res => {
        this.list = res;
      })
    },
  },
}
</script>

<style scoped>

</style>